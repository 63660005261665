<template>
  <v-overlay
    :value="processing"
  >
    <v-progress-circular
      indeterminate
      color="primary"
      size="48"
    />
  </v-overlay>
</template>
<script>
  export default {
    name: 'OverlayComponent',
    props: {
      processing: {
        type: Boolean,
        required: true,
      },
    },
    data: function () {
      return {
      }
    },
  }
</script>
